
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { FilterMatchMode } from "primevue/api";

import Button from "primevue/button";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";

import { ShipItem } from "@/types/customer";

import SalesOrder from "@/types/salesorder";

export default defineComponent({
  name: "ShipToAddressesTable",
  components: {
    Dialog,
    Button,
    DataTable,
    Column,
    InputText,
  },
  props: {
    shipToAddresses: {
      type: Array,
      required: true,
    },
    hideNewAddressButton: {
      type: Boolean,
      default: false,
    },
    showAllButton: {
      type: Boolean,
      default: true,
    },
    salesOrder: {
      type: SalesOrder,
      default: () => ({}),
    },
  },
  emits: ["openAddAddressDialog", "onRowClick", "onClose"],
  computed: {
    ...mapGetters({
      hideUseNewAddressButton: "mrkControl/hideUseNewAddress",
    }),
    showAddressButton(): boolean {
      return !this.hideNewAddressButton && !this.hideUseNewAddressButton;
    },
  },
  data() {
    return {
      showDialog: false,
      rowsPerPage: 10,
      rowsPerPageOptions: [5, 10, 25, 50],
      filters: {} as any,
    };
  },
  created() {
    this.resetFilters();
  },
  methods: {
    handleCloseModeOptionsDialog() {
      this.showDialog = false;
      this.$emit("onClose");
      this.resetFilters();
    },
    handleUseNewAddress() {
      this.$emit("openAddAddressDialog");
    },
    resetFilters() {
      this.filters = {
        ship_seq: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_name: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_city: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_state: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_zip: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_country: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        ship_attn: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
      };
    },
    isAddressSelected(data: ShipItem) {
      const shipAddress = this.salesOrder.shipItemAddress;
      return shipAddress.compareTo(data);
    },
    handleRowName(row: any): string {
      const isAddressSelected = this.isAddressSelected(row);
      return isAddressSelected ? "row-selected" : "";
    },
    handleRowClick(row: any) {
      this.$emit("onRowClick", row);
    },
  },
});
