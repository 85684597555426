
import { defineComponent } from "vue";
import Card from "primevue/card";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import CheckBox from "primevue/checkbox";
import CustomAccordion from "@/components/UI/CustomAccordion.vue";
import AccordionTab from "primevue/accordiontab";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Row from "primevue/row";
import ColumnGroup from "primevue/columngroup";
import AddTaxDialog from "./AddTaxDialog.vue";
import AddCreditMemoDialog from "./AddCreditMemoDialog.vue";
import Utils from "@/utility/utils";
import { mapGetters } from "vuex";
import AddressItems from "@/components/UI/AddressItems.vue";
import ShipToAddressesTable from "@/components/Pos/ShipToAddressesTable.vue";
import StaxService from "@/services/StaxService";

const staxService = new StaxService();

export default defineComponent({
  name: "Ship-tax-credits",
  components: {
    Card,
    Divider,
    InputText,
    InputNumber,
    Button,
    CheckBox,
    CustomAccordion,
    AccordionTab,
    Dropdown,
    DataTable,
    Column,
    ColumnGroup,
    Row,
    AddTaxDialog,
    AddCreditMemoDialog,
    AddressItems,
    ShipToAddressesTable,
  },
  computed: {
    ...mapGetters({
      getActiveOrderTab: "salesInquiry/getActiveOrderTab",
      shipViaItems: "shipControl/getShipViaItems",
      getSalesTaxCodes: "stax/getSalesTaxCodes",
    }),
    ccTotal(): any {
      return 0.0; //Utils.reduceTotal(this.creditInfo.cards, 'amount')
    },

    totalTaxPercent(): string {
      return (
        Utils.reduceTotal(
          this.currentOrder.tax_codes_items,
          "tax_code_rate",
        ).toFixed(2) + "%"
      );
    },

    totalTaxAmount(): any {
      return this.currentOrder.tax_amount;
    },
    selectedAddress(): any {
      return { ship_seq: this.currentOrder.ship_seq };
    },
  },

  data: () => ({
    currentId: "",
    loadingSalesTax: false,
    freightCodeOptions: ["Prepaid", "Collect", "Third Party", "Freight Free"],
    freightTypeOptions: ["Estimated", "Actual"],
    currentOrder: {} as any,
  }),
  created() {
    if (this.$attrs.orderId) {
      this.currentId = this.$attrs.orderId as string;
      this.currentOrder = this.getActiveOrderTab(this.currentId);
    }
  },
  mounted() {
    if (this.$attrs.orderId) {
      this.currentId = this.$attrs.orderId as string;
      this.currentOrder = this.getActiveOrderTab(this.currentId);
    }
  },
  methods: {
    calcTax() {
      const taxable = this.currentOrder.lis_items.some((item: any) => {
        return item.taxable === "Y";
      });

      if (!taxable) {
        this.currentOrder.tax_amount = "";
        return;
      }

      this.loadingSalesTax = true;
      staxService
        .calcStax(this.currentOrder)
        .then((response: any) => {
          this.currentOrder.tax_amount = response.tax_amount;
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingSalesTax = false;
        });
    },
    addTax(data: any) {
      if (!this.currentOrder.tax_codes_items) {
        this.currentOrder.tax_codes_items = [
          { tax_codes: data.code, tax_code_rate: data.rate },
        ];
      } else {
        this.currentOrder.tax_codes_items.push({
          tax_codes: data.code,
          tax_code_rate: data.rate,
        });
      }
    },
    deleteTax(code: string | number) {
      this.currentOrder.tax_codes_items =
        this.currentOrder.tax_codes_items.filter(
          (element: any) => element.code !== code,
        );
    },
    addCreditMemo(newCreditMemo: any) {
      console.log(newCreditMemo);
    },

    deleteCreditMemo(id: string | number) {
      console.log(id);
    },

    updateShipAddress(address: any) {
      this.currentOrder.ship_address_items = address;
    },
    handleRowClick(data: any) {
      Utils.setOrderAddress(
        this.currentOrder.customer,
        this.currentOrder,
        data.data,
        this.getSalesTaxCodes,
      );
    },
  },
  watch: {
    getActiveOrderTab: {
      handler: function (val: any) {
        this.currentOrder = val;
      },
      deep: true,
    },
  },
});
